.App {

  header {
    position: relative;
    background: #fff;
    color: #469;
    padding: 20px;
    border-bottom: 1px solid #eee;

    h1 {
      display: inline-block;
      img {
        height: 80px;
        vertical-align: middle;
      }
      line-height: 80px;
    }

    h2 {
      display: inline-block;
      font-style: italic;
      color: #999;
      line-height: 80px;
    }

    @media (max-width: 760px) {
      padding-top: 60px;

      h1 {
        margin-bottom: 0;
        line-height: auto;
      }

      h2 {
        font-size: 16px;
        margin: 0;
        line-height: 16px;
        float: right;
      }

      &:after {
        content: ' ';
        display: block;
        clear: both;
      }
    }
  }

  nav {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0 20px;
    background: #fefefe;
    box-shadow: 0 5px 10px rgba(0,0,0,.05);

    a {
      display: inline-block;
      padding: 20px;
      text-decoration: none;
      color: #333;
      &:hover {
        color: #666;
        background: #0001;
      }
    }

    @media (max-width: 760px) {
      padding: 0 10px;

      a {
        padding: 20px 10px;
        font-size: 14px;
      }
    }
  }

  section.text {
    margin: 0 auto;
    padding: 60px 20px 0;
    max-width: 1000px;
  }

  footer {
    background: #222;
    background: linear-gradient(#333, #222);
    color: #ccc;
    padding: 40px 20px;
    text-align: center;
    margin-top: 100px;
  }
}
