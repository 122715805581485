.image-item {
  padding: 20px;
  margin: 10px 20px;

  min-height: 95vh;

  @media (max-width: 760px) {
    margin: 10px 0;
    min-height: 60vh;
  }

  background: #fafafa;
  text-align: center;

  color: #666;

  box-shadow: inset 0 5px 20px #eee;

  .info {
    display: inline-block;
    text-align: left;
    background: #fff;
    padding: 20px 40px;
    min-width: 280px;
    width: 30vw;
    box-shadow: 0 5px 20px #eee;

    @media (max-width: 760px) {
      font-size: 14px;
      padding: 20px;
    }
  }

  &--image {
    padding: 20px;

    @media (max-width: 760px) {
      padding: 20px 0;
    }
    img {
      max-width: 100%;
      max-height: 85vh;
      box-shadow: 0 5px 20px #ccc;
    }
  }
}