@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400);
@import url(https://fonts.googleapis.com/css?family=Lato);
* {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab", Georgia, sans-serif;
  font-weight: 400; }

@media (max-width: 760px) {
  ul {
    padding-left: 20px; } }

a {
  color: #136; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.image-item {
  padding: 20px;
  margin: 10px 20px;
  min-height: 95vh;
  background: #fafafa;
  text-align: center;
  color: #666;
  box-shadow: inset 0 5px 20px #eee; }
  @media (max-width: 760px) {
    .image-item {
      margin: 10px 0;
      min-height: 60vh; } }
  .image-item .info {
    display: inline-block;
    text-align: left;
    background: #fff;
    padding: 20px 40px;
    min-width: 280px;
    width: 30vw;
    box-shadow: 0 5px 20px #eee; }
    @media (max-width: 760px) {
      .image-item .info {
        font-size: 14px;
        padding: 20px; } }
  .image-item--image {
    padding: 20px; }
    @media (max-width: 760px) {
      .image-item--image {
        padding: 20px 0; } }
    .image-item--image img {
      max-width: 100%;
      max-height: 85vh;
      box-shadow: 0 5px 20px #ccc; }

.App header {
  position: relative;
  background: #fff;
  color: #469;
  padding: 20px;
  border-bottom: 1px solid #eee; }
  .App header h1 {
    display: inline-block;
    line-height: 80px; }
    .App header h1 img {
      height: 80px;
      vertical-align: middle; }
  .App header h2 {
    display: inline-block;
    font-style: italic;
    color: #999;
    line-height: 80px; }
  @media (max-width: 760px) {
    .App header {
      padding-top: 60px; }
      .App header h1 {
        margin-bottom: 0;
        line-height: auto; }
      .App header h2 {
        font-size: 16px;
        margin: 0;
        line-height: 16px;
        float: right; }
      .App header:after {
        content: ' ';
        display: block;
        clear: both; } }

.App nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0 20px;
  background: #fefefe;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  .App nav a {
    display: inline-block;
    padding: 20px;
    text-decoration: none;
    color: #333; }
    .App nav a:hover {
      color: #666;
      background: #0001; }
  @media (max-width: 760px) {
    .App nav {
      padding: 0 10px; }
      .App nav a {
        padding: 20px 10px;
        font-size: 14px; } }

.App section.text {
  margin: 0 auto;
  padding: 60px 20px 0;
  max-width: 1000px; }

.App footer {
  background: #222;
  background: linear-gradient(#333, #222);
  color: #ccc;
  padding: 40px 20px;
  text-align: center;
  margin-top: 100px; }

