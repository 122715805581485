@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400");
@import url("https://fonts.googleapis.com/css?family=Lato");
$headerFont: 'Roboto Slab',Georgia,sans-serif;
$bodyFont: 'Lato', Helvetica, Arial, sans-serif;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $bodyFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headerFont;
  font-weight: 400;
}

@media (max-width: 760px) {
  ul {
    padding-left: 20px;
  }
}

a {
  color: #136;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
